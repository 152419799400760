import Vue from 'vue'

export default {
  state: {
    token: localStorage.getItem('token') || '',
    user: {},
    registration: false,
    avis: false,
    suppression_photo: false,
    demande_rappel: false,
    envoi_mail_proche: false
  },
  getters: {
    isLoggedIn: state => !!state.token,
    user: state => state.user,
    success: state => state.registration,
    avisLaisse: state => state.avis,
    suppression_photo: state => state.suppression_photo,
    demande_rappel: state => state.demande_rappel,
    envoi_mail_proche: state => state.envoi_mail_proche
  },
  mutations: {
    UPDATE_USER (state, user) {
      state.user = user
    },
    SET_TOKEN (state, token) {
      state.token = token
      Vue.prototype.$api.defaults.headers.common.Authorization = token
      Vue.prototype.$api.defaults.headers.common.isInterne = localStorage.getItem('interne')
      localStorage.setItem('token', token)
    },
    LOGOUT (state) {
      state.token = ''
      state.user = {}
    },
    REGISTRATION_SUCCESS (state) {
      state.registration = true
    },
    SUPPRESSION_PHOTO_SUCCESS (state) {
      state.suppression_photo = true
    },
    DEMANDE_RAPPEL_SUCCESS (state) {
      state.demande_rappel = true
    },
    ENVOI_MAIL_PROCHE_SUCCESS (state) {
      state.envoi_mail_proche = true
    },
    AVIS_SUCCESS (state) {
      state.avis = true
    }
  },
  actions: {
    login ({ commit }, user) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.post('/espace-client/login', user)
          .then(res => {
            const token = res.data.token
            this.dispatch('loadCurrentUser', token)
              .then(() => resolve(res))
              .catch(err => console.log(err))
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    avis ({ commit }, message) {
      var params = {
        message: message
      }
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.post('/espace-client/avis', params)
          .then(res => {
            commit('AVIS_SUCCESS')
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    updateVisite ({ commit }, page) {
      var params = {
        page: page,
        session: localStorage.getItem('token')
      }
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.post('/espace-client/update-visite', params)
          .then(res => {
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    rdv ({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.post('/espace-client/rdv', data)
          .then(res => {
            commit('REGISTRATION_SUCCESS')
            dispatch('loadCurrentUser', localStorage.getItem('token')) // Refresh user
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    loadCurrentUser ({ commit }, token) {
      return new Promise((resolve, reject) => {
        commit('SET_TOKEN', token)
        Vue.prototype.$api.get('/espace-client/contact-infos')
          .then(res => {
            commit('UPDATE_USER', res.data)
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getVendeurInfos ({ commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.get(`/espace-client/vendeur-infos/${id}`)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    supprimerPhoto ({ commit, dispatch }, photo) {
      var params = {
        photo: photo
      }
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.post('espace-client/ec-remove-photo/', params)
          .then(res => {
            commit('SUPPRESSION_PHOTO_SUCCESS')
            window.scrollTo(0, 0)
            dispatch('loadCurrentUser', localStorage.getItem('token')) // Refresh user
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    demandeRappel ({ commit }, commercialEmail) {
      var params = {
        telephone: this.getters.user.tel1,
        commercialEmail: commercialEmail
      }
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.post('espace-client/ec-demande-rappel/', params)
          .then(res => {
            commit('DEMANDE_RAPPEL_SUCCESS')
            window.scrollTo(0, 0)
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    envoiMailProche ({ commit }, mail) {
      var params = {
        mail: mail
      }
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.post('espace-client/ec-envoi-mail-proche/', params)
          .then(res => {
            commit('ENVOI_MAIL_PROCHE_SUCCESS')
            window.scrollTo(0, 0)
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    logout ({ commit }) {
      var params = {
        id: this.getters.user.n
      }
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.post('espace-client/ec-deconnexion/', params)
          .then(res => {
            commit('LOGOUT')
            localStorage.removeItem('token')
            if (sessionStorage.getItem('admin')) sessionStorage.removeItem('admin')
            localStorage.setItem('interne', 0)
            delete Vue.prototype.$api.defaults.headers.common.Authorization
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    }

  }
}
